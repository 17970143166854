<template>
  <div class="container section">
    <div class="columns is-centered">
      <div class="column is-two-fifths-desktop is-half-tablet">
        <card-component
          :image="logo.image_logo_crop"
          title="Selecciona un local"
          icon="store"
        >
          <form @submit.prevent="saveLocal">
            <b-field
              :type="{ 'is-danger': $v.local.$error }"
              :message="[
                { 'Requerido. Selecciona un local': !$v.local.required && $v.local.$error }
              ]"
            >
              <b-select expanded placeholder="Elige una opción" v-model="local">
                <option
                  v-for="lcl in locals"
                  :value="lcl.id"
                  :key="lcl.id">
                  {{ lcl.name }}
                </option>
              </b-select>
            </b-field>
            <hr>
            <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">
              Continuar
            </button>
            <b-button type="is-danger" class="ml-2" @click="logout">
              Salir
            </b-button>
          </form>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import LocalService from '@/services/LocalService'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'SelectLocal',
  components: {
    CardComponent
  },
  data () {
    return {
      locals: [],
      logo: '',
      local: null,
      isLoading: false
    }
  },
  validations: {
    local: { required }
  },
  created () {
    this.getLocalList()
  },
  methods: {
    async getLocalList () {
      this.isLoading = true
      try {
        const response = await LocalService.getLocalList()
        this.locals = response.data.locals
        this.logo = response.data.logo
        this.$localStorage.set('company_logo', this.logo.image_logo_crop)
      } catch (e) {
        const message = `Error: ${e.response.data.non_field_errors[0]}`
        this.$buefy.toast.open({
          message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    saveLocal () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$localStorage.set('local', this.local)
        this.$router.push({ name: 'home' })
      }
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
